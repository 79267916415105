import { capitalize } from 'lodash'
import { ComposerTranslation } from 'vue-i18n'
import { User } from '../useUsers'

export const createKeyValueSentence = ([key, value]: [string, string]) =>
  `${capitalize(
    key.replace(/([A-Z]+)/g, ' $1').toLowerCase()
  )} ${value}.`.trim()

export const getUserInitials = (user: User) =>
  (user.firstName.substring(0, 1) + user.lastName.substring(0, 1)).toUpperCase()

export const getUserFullName = (user: User) =>
  `${user.firstName.trim()} ${user.lastName.trim()}`

export const getUserFullNameSafe = (
  user: User | undefined,
  t: ComposerTranslation
) =>
  user
    ? getUserFullName(user) +
      (user.isDeleted ? ` ${t('global.user.deletedHint')}` : '')
    : t('global.user.deleted')
